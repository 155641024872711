import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { set, ref, push, child } from "firebase/database";
import { db } from "../firebase/firebase";

const Contact = () => {
  const [buttonText, setButtonText] = useState("Send Message");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const resetForm = (e) => {
    setTimeout(() => {
      e.target.reset();
      setButtonText("Send Message");
    }, 3000);
  };

  const onSubmit = (data, e) => {
    const enquiryKey = push(child(ref(db), "/enquiries")).key;

    setButtonText("Sending Message please wait...");
    set(ref(db, `/enquiries/${enquiryKey}`), data)
      .then(() => {
        const notificationContent = {
          createdAt: Date.now().valueOf(),
          name: data.name,
          readStatus: false,
          redirectURL: `/admin/enquiries/view/${enquiryKey}`,
          type: "New message",
        };

        const notificationKey = push(child(ref(db), "/notifications")).key;
        set(ref(db, `/notifications/${notificationKey}`), notificationContent);

        setButtonText("Message sent successfully");
        resetForm(e);
      })
      .catch(() => {
        setButtonText("Something went wrong");
        resetForm(e);
      });
  };

  return (
    <>
      <form className="contactform" onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="form-group">
              <input
                {...register("name", { required: true })}
                type="text"
                name="name"
                placeholder="YOUR NAME"
              />
              {errors.name && errors.name.type === "required" && (
                <span className="invalid-feedback">Name is required</span>
              )}
            </div>
          </div>
          {/* End .col */}

          <div className="col-12 col-md-6">
            <div className="form-group">
              <input
                {...register(
                  "email",
                  {
                    required: "Email is Required",
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "Entered value does not match email format",
                    },
                  },
                  { required: true }
                )}
                type="email"
                name="email"
                placeholder="YOUR EMAIL"
              />
              {errors.email && (
                <span className="invalid-feedback">{errors.email.message}</span>
              )}
            </div>
          </div>
          {/* End .col */}

          <div className="col-12 col-md-12">
            <div className="form-group">
              <input
                {...register("subject", { required: true })}
                type="text"
                name="subject"
                placeholder="YOUR SUBJECT"
              />
              {errors.subject && (
                <span className="invalid-feedback">Message is required.</span>
              )}
            </div>
          </div>
          {/* End .col */}

          <div className="col-12">
            <div className="form-group">
              <textarea
                {...register("message", { required: true })}
                name="message"
                placeholder="YOUR MESSAGE"
              ></textarea>
              {errors.message && (
                <span className="invalid-feedback">Message is required.</span>
              )}
            </div>
          </div>
          {/* End .col */}

          <div className="col-12">
            <button type="submit" className="button">
              <span className="button-text">{buttonText}</span>
              <span className="button-icon fa fa-send"></span>
            </button>
          </div>
          {/* End .col */}
        </div>
      </form>

      {/* End contact */}
    </>
  );
};

export default Contact;
