import React from "react";
import { useAppStateValue } from "../contexts/AppStateProvider";

const Address = () => {
  const [{ profile }] = useAppStateValue();

  return (
    <>
      <p className="open-sans-font custom-span-contact position-relative">
        <i className="fa fa-map position-absolute"></i>
        <span className="d-block">Address Point</span>
        {profile?.aboutMe?.address}
      </p>
      {/* End .custom-span-contact */}

      <p className="open-sans-font custom-span-contact position-relative">
        <i className="fa fa-envelope-open position-absolute"></i>
        <span className="d-block">mail me</span>{" "}
        <a href={`mailto:${profile?.aboutMe?.email}`}>
          {profile?.aboutMe?.email}
        </a>
      </p>
      {/* End .custom-span-contact */}

      <p className="open-sans-font custom-span-contact position-relative">
        <i className="fa fa-phone-square position-absolute"></i>
        <span className="d-block">call me</span>{" "}
        <a href={`Tel: ${profile?.aboutMe?.phone}`}>
          {profile?.aboutMe?.phone}
        </a>
      </p>
      {/* End .custom-span-contact */}
    </>
  );
};

export default Address;
