import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ModalContent = (article) => {
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        draggable: false,
    };

    return (
        <div className="slideshow">
            <figure>
                {/* Project Details Starts */}
                <figcaption>
                    <h3>{article.title}</h3>
                    <div className="row open-sans-font">
                        <div className="col-12 mb-2">
                            <i className="fa fa-file-text-o pr-2"></i>
                            <span className="project-label">Published in </span>
                            :{" "}
                            <span className="ft-wt-600 uppercase">
                                {article.publishedIn}
                            </span>
                        </div>
                        {/* End .col */}

                        <div className="col-12 mb-2">
                            <i className="fa fa-external-link pr-2"></i>
                            <span className="project-label">Article Link </span>
                            :{" "}
                            <span className="ft-wt-600 uppercase">
                                <a
                                    href={article.articleLink}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {article.title.toLowerCase()}
                                </a>
                            </span>
                        </div>
                        {/* End .col */}

                        <div className="col-12 mb-2">
                            <i className="fa fa-info-circle pr-2"></i>
                            <span className="project-label">
                                Description{" "}
                            </span>:{" "}
                            <span className="ft-wt-600 uppercase">
                                {article.description}
                            </span>
                        </div>
                        {/* End .col */}
                    </div>
                    {/* End .row */}
                </figcaption>
                {/* Project Details Ends */}

                {/*  Main Project Content Starts */}
                <Slider {...settings}>
                    <div className="slide_item">
                        <img src={article.image.url} alt="slide 1" />
                    </div>
                </Slider>
                {/* Main Project Content Ends */}
            </figure>
        </div>
        //  End .slideshow
    );
};

export default ModalContent;
