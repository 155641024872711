import { useAppStateValue } from "../../contexts/AppStateProvider";
import { appActionTypes } from "../../reducers/app";

const HeroLight = () => {
    const [{ profile }, dispatch] = useAppStateValue();

    const switchToTab = (index) => {
        dispatch({
            type: appActionTypes.SWICH_TAB,
            index,
        });
    };

    return (
        <>
            <div className="row home-details-container align-items-center">
                <div
                    className="col-lg-4 bg position-fixed d-none d-lg-block"
                    style={{
                        backgroundImage: `url(${profile?.aboutMe?.profilePic})`,
                    }}
                ></div>
                <div className="col-12 col-lg-8 offset-lg-4 home-details  text-center text-lg-start">
                    <div>
                        <img
                            src={profile?.aboutMe?.profilePic}
                            className="img-fluid main-img-mobile d-sm-block d-lg-none"
                            alt="hero man"
                        />
                        <h1 className="text-uppercase poppins-font">
                            I'm {profile?.aboutMe?.firstName}{" "}
                            {profile?.aboutMe?.lastName}.
                            <span>{profile?.aboutMe?.title}</span>
                        </h1>
                        <p className="open-sans-font">
                            {profile?.aboutMe?.description}
                        </p>
                        <button
                            className="button mx-2 my-3"
                            onClick={() => switchToTab(1)}
                        >
                            <span className="button-text">More About Me</span>
                            <span className="button-icon fa fa-arrow-right"></span>
                        </button>
                        <button
                            className="button mx-2 my-3"
                            onClick={() => switchToTab(2)}
                        >
                            <span className="button-text">My Projects</span>
                            <span className="button-icon fa fa-arrow-right"></span>
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default HeroLight;
