import React from "react";
import Achievements from "./Achievements";
import Summary from "./Summary";
import Education from "./Education";
import Experience from "./Experience";
import PersonalInfo from "./PersonalInfo";
import Skills from "./Skills";
import { useAppStateValue } from "../../contexts/AppStateProvider";

const Index = () => {
    const [{ profile }] = useAppStateValue();

    const downloadResume = (url, fileName) => {
        const xhr = new XMLHttpRequest();
        xhr.responseType = "blob";
        xhr.onload = (event) => {
            const blob = xhr.response;

            const isIE = false || !!document.documentMode;
            if (isIE) {
                window.navigator.msSaveBlob(blob, fileName);
            } else {
                const url = window.URL || window.webkitURL;
                const link = url.createObjectURL(blob);
                const a = document.createElement("a");
                a.setAttribute("download", fileName);
                a.setAttribute("href", link);
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            }
        };
        xhr.open("GET", url);
        xhr.send();
    };

    return (
        <section className="main-content ">
            <div className="container">
                <div className="row">
                    {/* Personal Info Starts */}

                    <div className="col-xl-6 col-lg-5 col-12">
                        <div className="row">
                            <div className="col-12">
                                <h3 className="text-uppercase custom-title mb-0 ft-wt-600">
                                    personal infos
                                </h3>
                            </div>
                            {/* End .col */}

                            <div className="col-12">
                                <PersonalInfo />
                            </div>
                            {/* End personal info */}

                            <div className="col-12 mt-1">
                                <button
                                    className="button"
                                    onClick={() =>
                                        downloadResume(
                                            profile.aboutMe.resume,
                                            "vishnucprasad.pdf"
                                        )
                                    }
                                >
                                    <span className="button-text">
                                        Download CV
                                    </span>
                                    <span className="button-icon fa fa-download"></span>
                                </button>
                            </div>
                            {/* End download button */}
                        </div>
                    </div>
                    {/*  Personal Info Ends */}

                    {/*  Boxes Starts */}
                    <div className="col-xl-6 col-lg-7 col-12 mt-5 mt-lg-0">
                        <Achievements />
                    </div>
                    {/* Achievements Ends */}
                </div>
                {/* End .row */}

                <hr className="separator" />

                {/* Skills Starts */}
                <div className="row">
                    <div className="col-12">
                        <h3 className="text-uppercase pb-4 pb-sm-5 mb-3 mb-sm-0 text-left text-sm-center custom-title ft-wt-600">
                            My Skills
                        </h3>
                    </div>
                    <Skills />
                </div>
                {/* Skills Ends */}

                <hr className="separator mt-1" />

                {/* Experience & Education Starts */}
                <div className="row">
                    <div className="col-lg-6 m-15px-tb">
                        <h3 className="text-uppercase pb-5 mb-0 text-left custom-title ft-wt-600">
                            Summary
                        </h3>
                        <div className="resume-box">
                            <Summary />
                        </div>
                    </div>
                    <div className="col-lg-6 m-15px-tb">
                        <h3 className="text-uppercase pb-5 mb-0 text-left custom-title ft-wt-600">
                            Experience
                        </h3>
                        <div className="resume-box">
                            <Experience />
                        </div>
                    </div>
                    <div className="col-lg-6 m-15px-tb">
                        <h3 className="text-uppercase pb-5 mb-0 text-left custom-title ft-wt-600">
                            Education
                        </h3>
                        <div className="resume-box">
                            <Education />
                        </div>
                    </div>
                </div>
                {/*  Experience & Education Ends */}
            </div>
        </section>
    );
};

export default Index;
