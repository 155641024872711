export const appInitialState = {
    selectedTabIndex: 0,
    projects: [],
    articles: [],
    profile: null,
};

export const appActionTypes = {
    SET_STATE: "SET_STATE",
    SWICH_TAB: "SWICH_TAB",
    TOGGLE_IS_OPEN: "TOGGLE_IS_OPEN",
    TOGGLE_ARTICLE_IS_OPEN: "TOGGLE_ARTICLE_IS_OPEN",
};

const appReducer = (state, action) => {
    switch (action.type) {
        case appActionTypes.SET_STATE:
            return {
                ...state,
                ...action.state,
            };
        case appActionTypes.SWICH_TAB:
            return {
                ...state,
                selectedTabIndex: action.index,
            };
        case appActionTypes.TOGGLE_IS_OPEN:
            return {
                ...state,
                projects: state.projects.map((project) => {
                    if (project._id === action.projectId) {
                        return {
                            ...project,
                            isOpen: !project.isOpen,
                        };
                    }

                    return project;
                }),
            };
        case appActionTypes.TOGGLE_ARTICLE_IS_OPEN:
            return {
                ...state,
                articles: state.articles.map((article) => {
                    if (article._id === action.articleId) {
                        return {
                            ...article,
                            isOpen: !article.isOpen,
                        };
                    }

                    return article;
                }),
            };
        default:
            return state;
    }
};

export default appReducer;
