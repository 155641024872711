import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Modal from "react-modal";
import ModalContent from "./modal/ModalContent";
import { useAppStateValue } from "../../contexts/AppStateProvider";
import { appActionTypes } from "../../reducers/app";

const Portfolio = () => {
    const [{ projects }, dispatch] = useAppStateValue();

    const appProjects = projects.filter((project) => project.type === "App");
    const apiProjects = projects.filter((project) => project.type === "Api");
    const webProjects = projects.filter(
        (project) => project.type === "Website"
    );

    const toggleModal = (projectId) => {
        dispatch({
            type: appActionTypes.TOGGLE_IS_OPEN,
            projectId,
        });
    };

    return (
        <Tabs>
            <TabList className="portfolio-tab-list">
                <Tab>ALL</Tab>
                <Tab>APP</Tab>
                <Tab>API</Tab>
                <Tab>WEBSITE</Tab>
            </TabList>

            <div className="portfolio-tab-content">
                <TabPanel>
                    <ul className="row grid justify-content-center">
                        {projects.map((project) => (
                            <li
                                data-aos="fade-right"
                                data-aos-duration="1200"
                                data-aos-delay="0"
                            >
                                <figure
                                    onClick={() => toggleModal(project._id)}
                                >
                                    <img
                                        src={project.images[0].url}
                                        alt="Portolio"
                                    />
                                    <div className=" hover-content-wrapper">
                                        <span className="content-title">
                                            {project.title}
                                        </span>
                                    </div>
                                </figure>

                                {/* Start ModalOneContent */}
                                <Modal
                                    isOpen={project.isOpen}
                                    onRequestClose={() =>
                                        toggleModal(project._id)
                                    }
                                    contentLabel="My dialog"
                                    className="custom-modal dark"
                                    overlayClassName="custom-overlay dark"
                                    closeTimeoutMS={500}
                                >
                                    <div>
                                        <button
                                            className="close-modal"
                                            onClick={() =>
                                                toggleModal(project._id)
                                            }
                                        >
                                            <img
                                                src="/img/cancel.svg"
                                                alt="close icon"
                                            />
                                        </button>
                                        {/* End close icon */}

                                        <div className="box_inner portfolio">
                                            <ModalContent {...project} />
                                        </div>
                                    </div>
                                </Modal>
                                {/* End  ModalOneContent */}
                            </li>
                        ))}
                    </ul>
                </TabPanel>
                {/* End All Project */}

                <TabPanel>
                    <ul className="row grid justify-content-center">
                        {appProjects.map((project) => (
                            <li
                                data-aos="fade-right"
                                data-aos-duration="1200"
                                data-aos-delay="0"
                            >
                                <figure
                                    onClick={() => toggleModal(project._id)}
                                >
                                    <img
                                        src={project.images[0].url}
                                        alt="Portolio"
                                    />
                                    <div className=" hover-content-wrapper">
                                        <span className="content-title">
                                            {project.title}
                                        </span>
                                    </div>
                                </figure>

                                {/* Start ModalOneContent */}
                                <Modal
                                    isOpen={project.isOpen}
                                    onRequestClose={() =>
                                        toggleModal(project._id)
                                    }
                                    contentLabel="My dialog"
                                    className="custom-modal dark"
                                    overlayClassName="custom-overlay dark"
                                    closeTimeoutMS={500}
                                >
                                    <div>
                                        <button
                                            className="close-modal"
                                            onClick={() =>
                                                toggleModal(project._id)
                                            }
                                        >
                                            <img
                                                src="/img/cancel.svg"
                                                alt="close icon"
                                            />
                                        </button>
                                        {/* End close icon */}

                                        <div className="box_inner portfolio">
                                            <ModalContent {...project} />
                                        </div>
                                    </div>
                                </Modal>
                                {/* End  ModalOneContent */}
                            </li>
                        ))}
                    </ul>
                </TabPanel>
                {/* End App Project */}

                <TabPanel>
                    <ul className="row grid justify-content-center">
                        {apiProjects.map((project) => (
                            <li
                                data-aos="fade-right"
                                data-aos-duration="1200"
                                data-aos-delay="0"
                            >
                                <figure
                                    onClick={() => toggleModal(project._id)}
                                >
                                    <img
                                        src={project.images[0].url}
                                        alt="Portolio"
                                    />
                                    <div className=" hover-content-wrapper">
                                        <span className="content-title">
                                            {project.title}
                                        </span>
                                    </div>
                                </figure>

                                {/* Start ModalOneContent */}
                                <Modal
                                    isOpen={project.isOpen}
                                    onRequestClose={() =>
                                        toggleModal(project._id)
                                    }
                                    contentLabel="My dialog"
                                    className="custom-modal dark"
                                    overlayClassName="custom-overlay dark"
                                    closeTimeoutMS={500}
                                >
                                    <div>
                                        <button
                                            className="close-modal"
                                            onClick={() =>
                                                toggleModal(project._id)
                                            }
                                        >
                                            <img
                                                src="/img/cancel.svg"
                                                alt="close icon"
                                            />
                                        </button>
                                        {/* End close icon */}

                                        <div className="box_inner portfolio">
                                            <ModalContent {...project} />
                                        </div>
                                    </div>
                                </Modal>
                                {/* End  ModalOneContent */}
                            </li>
                        ))}
                    </ul>
                </TabPanel>
                {/* End Api Project */}

                <TabPanel>
                    <ul className="row grid justify-content-center">
                        {webProjects.map((project) => (
                            <li
                                data-aos="fade-right"
                                data-aos-duration="1200"
                                data-aos-delay="0"
                            >
                                <figure
                                    onClick={() => toggleModal(project._id)}
                                >
                                    <img
                                        src={project.images[0].url}
                                        alt="Portolio"
                                    />
                                    <div className=" hover-content-wrapper">
                                        <span className="content-title">
                                            {project.title}
                                        </span>
                                    </div>
                                </figure>

                                {/* Start ModalOneContent */}
                                <Modal
                                    isOpen={project.isOpen}
                                    onRequestClose={() =>
                                        toggleModal(project._id)
                                    }
                                    contentLabel="My dialog"
                                    className="custom-modal dark"
                                    overlayClassName="custom-overlay dark"
                                    closeTimeoutMS={500}
                                >
                                    <div>
                                        <button
                                            className="close-modal"
                                            onClick={() =>
                                                toggleModal(project._id)
                                            }
                                        >
                                            <img
                                                src="/img/cancel.svg"
                                                alt="close icon"
                                            />
                                        </button>
                                        {/* End close icon */}

                                        <div className="box_inner portfolio">
                                            <ModalContent {...project} />
                                        </div>
                                    </div>
                                </Modal>
                                {/* End  ModalOneContent */}
                            </li>
                        ))}
                    </ul>
                </TabPanel>
                {/* End Web Project */}
            </div>
        </Tabs>
    );
};

export default Portfolio;
