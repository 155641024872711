import React from "react";
import { useAppStateValue } from "../../contexts/AppStateProvider";

const PersonalInfo = () => {
  const [{profile}] = useAppStateValue();

  const personalInfoContent = [
    { meta: "first name", metaInfo: profile?.aboutMe?.firstName },
    { meta: "last name", metaInfo: profile?.aboutMe?.lastName },
    { meta: "Age", metaInfo: profile?.aboutMe?.age },
    { meta: "Nationality", metaInfo: profile?.aboutMe?.nationality },
    { meta: "Domain", metaInfo: profile?.aboutMe?.domain },
    { meta: "phone", metaInfo: profile?.aboutMe?.phone },
    { meta: "Email", metaInfo: profile?.aboutMe?.email },
    { meta: "Github", metaInfo: profile?.aboutMe?.github },
    { meta: "Linkedin", metaInfo: profile?.aboutMe?.linkedin },
    { meta: "langages", metaInfo: profile?.aboutMe?.knownLanguages },
  ];

  return (
    <ul className="about-list list-unstyled open-sans-font">
      {personalInfoContent.map((val, i) => (
        <li key={i}>
          <span className="title">{val.meta}: </span>
          <span className="value d-block d-sm-inline-block d-lg-block d-xl-inline-block">
            {val.metaInfo}
          </span>
        </li>
      ))}
    </ul>
  );
};

export default PersonalInfo;
