import React, { useEffect, useState } from "react";
import Routes from "./router/Routes";
import ScrollToTop from "./components/ScrollToTop";
import AOS from "aos";
import "aos/dist/aos.css";
import AnimatedCursor from "react-animated-cursor";
import { useAppStateValue } from "./contexts/AppStateProvider";
import { onValue, ref } from "firebase/database";
import { db } from "./firebase/firebase";
import { appActionTypes } from "./reducers/app";
import Loading from "./Loader";

const App = () => {
    const [, dispatch] = useAppStateValue();
    const [isLoading, setIsLoading] = useState(true);

    const snapshotToArray = (snapshot, child) => {
        const array = [];

        snapshot.child(child).forEach((childSnapshot) => {
            array.push({
                _id: childSnapshot.key,
                ...childSnapshot.val(),
            });
        });

        return array;
    };

    useEffect(() => {
        AOS.init();

        const unsubscribe = onValue(ref(db), (snapshot) => {
            if (snapshot.exists()) {
                const projects = snapshotToArray(snapshot, "projects")
                    .reverse()
                    .map((project) => ({
                        ...project,
                        isOpen: false,
                        images: snapshotToArray(
                            snapshot,
                            `projects/${project._id}/images`
                        ),
                    }));
                const articles = snapshotToArray(snapshot, "articles")
                    .reverse()
                    .map((project) => ({
                        ...project,
                        isOpen: false,
                    }));
                const profile = {
                    ...snapshot.child("profile").val(),
                    education: snapshotToArray(
                        snapshot,
                        "profile/education"
                    ).reverse(),
                    experience: snapshotToArray(
                        snapshot,
                        "profile/experience"
                    ).reverse(),
                };

                dispatch({
                    type: appActionTypes.SET_STATE,
                    state: {
                        projects,
                        articles,
                        profile,
                    },
                });

                setIsLoading(false);
            }
        });

        return () => {
            unsubscribe();
        };
    }, [dispatch]);

    return isLoading ? (
        <Loading />
    ) : (
        <>
            <AnimatedCursor
                innerSize={8}
                outerSize={44}
                color="255, 160, 1"
                outerAlpha={0.3}
                innerScale={0.7}
                outerScale={1.2}
            />
            <ScrollToTop />
            <Routes />
        </>
    );
};

export default App;
