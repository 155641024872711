import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Modal from "react-modal";
import ModalContent from "./modal/ModalContent";
import { appActionTypes } from "../../reducers/app";
import { useAppStateValue } from "../../contexts/AppStateProvider";

const PortfolioLight = () => {
  const [{ projects }, dispatch] = useAppStateValue();

  const webProjects = projects.filter((project) => project.type === "Website");
  const appProjects = projects.filter((project) => project.type === "App");

  const toggleModal = (projectId) => {
    dispatch({
      type: appActionTypes.TOGGLE_IS_OPEN,
      projectId,
    });
  };

  return (
    <Tabs>
      <TabList className="portfolio-tab-list">
        <Tab>ALL</Tab>
        <Tab>WEB</Tab>
        <Tab>APP</Tab>
      </TabList>

      <div className="portfolio-tab-content">
        <TabPanel>
          <ul className="row grid justify-content-center">
            {projects.map((project) => (
              <li
                data-aos="fade-right"
                data-aos-duration="1200"
                data-aos-delay="0"
              >
                <figure onClick={() => toggleModal(project._id)}>
                  <img src={project.images[0].url} alt="Portolio" />
                  <div className=" hover-content-wrapper">
                    <span className="content-title">{project.title}</span>
                  </div>
                </figure>

                {/* Start ModalOneContent */}
                <Modal
                  isOpen={project.isOpen}
                  onRequestClose={() => toggleModal(project._id)}
                  contentLabel="My dialog"
                  className="custom-modal dark"
                  overlayClassName="custom-overlay dark"
                  closeTimeoutMS={500}
                >
                  <div>
                    <button
                      className="close-modal"
                      onClick={() => toggleModal(project._id)}
                    >
                      <img src="/img/cancel.svg" alt="close icon" />
                    </button>
                    {/* End close icon */}

                    <div className="box_inner portfolio">
                      <ModalContent {...project} />
                    </div>
                  </div>
                </Modal>
                {/* End  ModalOneContent */}
              </li>
            ))}
          </ul>
        </TabPanel>
        {/* End All Project */}

        <TabPanel>
          <ul className="row grid justify-content-center">
            {webProjects.map((project) => (
              <li
                data-aos="fade-right"
                data-aos-duration="1200"
                data-aos-delay="0"
              >
                <figure onClick={() => toggleModal(project._id)}>
                  <img src={project.images[0].url} alt="Portolio" />
                  <div className=" hover-content-wrapper">
                    <span className="content-title">{project.title}</span>
                  </div>
                </figure>

                {/* Start ModalOneContent */}
                <Modal
                  isOpen={project.isOpen}
                  onRequestClose={() => toggleModal(project._id)}
                  contentLabel="My dialog"
                  className="custom-modal dark"
                  overlayClassName="custom-overlay dark"
                  closeTimeoutMS={500}
                >
                  <div>
                    <button
                      className="close-modal"
                      onClick={() => toggleModal(project._id)}
                    >
                      <img src="/img/cancel.svg" alt="close icon" />
                    </button>
                    {/* End close icon */}

                    <div className="box_inner portfolio">
                      <ModalContent {...project} />
                    </div>
                  </div>
                </Modal>
                {/* End  ModalOneContent */}
              </li>
            ))}
          </ul>
        </TabPanel>
        {/* End Web Project */}

        <TabPanel>
          <ul className="row grid justify-content-center">
            {appProjects.map((project) => (
              <li
                data-aos="fade-right"
                data-aos-duration="1200"
                data-aos-delay="0"
              >
                <figure onClick={() => toggleModal(project._id)}>
                  <img src={project.images[0].url} alt="Portolio" />
                  <div className=" hover-content-wrapper">
                    <span className="content-title">{project.title}</span>
                  </div>
                </figure>

                {/* Start ModalOneContent */}
                <Modal
                  isOpen={project.isOpen}
                  onRequestClose={() => toggleModal(project._id)}
                  contentLabel="My dialog"
                  className="custom-modal dark"
                  overlayClassName="custom-overlay dark"
                  closeTimeoutMS={500}
                >
                  <div>
                    <button
                      className="close-modal"
                      onClick={() => toggleModal(project._id)}
                    >
                      <img src="/img/cancel.svg" alt="close icon" />
                    </button>
                    {/* End close icon */}

                    <div className="box_inner portfolio">
                      <ModalContent {...project} />
                    </div>
                  </div>
                </Modal>
                {/* End  ModalOneContent */}
              </li>
            ))}
          </ul>
        </TabPanel>
        {/* End App Project */}
      </div>
    </Tabs>
  );
};

export default PortfolioLight;
