import React from "react";
import { useAppStateValue } from "../../contexts/AppStateProvider";

const Achievements = () => {
  const [{profile}] = useAppStateValue();

  const achievementsContent = [
    { title: profile?.flutterProjects, subTitle: "Flutter Projects" },
    { title: profile?.reactProjects, subTitle: "React.JS Projects" },
    { title: profile?.nodeProjects, subTitle: "Node.JS Projects" },
    { title: profile?.mernProjects, subTitle: "MERN Projects" },
  ];

  return (
    <div className="row">
      {achievementsContent.map((val, i) => (
        <div className="col-6" key={i}>
          <div className="box-stats with-margin">
            <h3 className="poppins-font position-relative">{val.title}</h3>
            <p className="open-sans-font m-0 position-relative text-uppercase">
              {val.subTitle}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Achievements;
