import React from "react";
import Modal from "react-modal";
import ModalContent from "./modal/ModalContent";
import { useAppStateValue } from "../../contexts/AppStateProvider";
import { appActionTypes } from "../../reducers/app";

const Article = () => {
    const [{ articles }, dispatch] = useAppStateValue();

    const toggleModal = (articleId) => {
        dispatch({
            type: appActionTypes.TOGGLE_ARTICLE_IS_OPEN,
            articleId,
        });
    };

    return (
        <ul className="row grid justify-content-center">
            {articles.map((article) => (
                <li
                    data-aos="fade-right"
                    data-aos-duration="1200"
                    data-aos-delay="0"
                >
                    <figure onClick={() => toggleModal(article._id)}>
                        <img src={article.image.url} alt="Portolio" />
                        <div className=" hover-content-wrapper">
                            <span className="content-title">
                                {article.title}
                            </span>
                        </div>
                    </figure>

                    {/* Start ModalOneContent */}
                    <Modal
                        isOpen={article.isOpen}
                        onRequestClose={() => toggleModal(article._id)}
                        contentLabel="My dialog"
                        className="custom-modal dark"
                        overlayClassName="custom-overlay dark"
                        closeTimeoutMS={500}
                    >
                        <div>
                            <button
                                className="close-modal"
                                onClick={() => toggleModal(article._id)}
                            >
                                <img src="/img/cancel.svg" alt="close icon" />
                            </button>
                            {/* End close icon */}

                            <div className="box_inner portfolio">
                                <ModalContent {...article} />
                            </div>
                        </div>
                    </Modal>
                    {/* End  ModalOneContent */}
                </li>
            ))}
        </ul>
    );
};

export default Article;
