import React from "react";
import { useAppStateValue } from "../../contexts/AppStateProvider";

const Education = () => {
  const [{profile}] = useAppStateValue();
  
  return (
    <ul>
      {profile?.education?.map((val, i) => (
        <li key={i}>
          <div className="icon">
            <i className="fa fa-briefcase"></i>
          </div>
          <span className="time open-sans-font text-uppercase">{val.timeLine}</span>
          <h5 className="poppins-font">
            <span className="text-uppercase">{val.title}</span>
            <span className="place open-sans-font">{val.description}</span>
          </h5>
        </li>
      ))}
    </ul>
  );
};

export default Education;
