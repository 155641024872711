import React from "react";
import { useAppStateValue } from "../../contexts/AppStateProvider";

const Experience = () => {
  const [{profile}] = useAppStateValue();

  return (
    <ul>
      {profile?.experience?.map((val, i) => (
        <li key={i}>
          <div className="icon">
            <i className="fa fa-briefcase"></i>
          </div>
          <span className="time open-sans-font text-uppercase">{val.timeLine}</span>
          <h5 className="poppins-font">
            <span className="text-uppercase">{val.title}</span>
            <span className="place open-sans-font">{val.description}</span>
          </h5>
          <p className="open-sans-font text-uppercase">{val.type}</p>
        </li>
      ))}
    </ul>
  );
};

export default Experience;
