import React from "react";
import { useAppStateValue } from "../../contexts/AppStateProvider";

const Summary = () => {
  const [{profile}] = useAppStateValue();

  return (
    <ul>
      <li>
        <div className="icon">
          <i className="fa fa-briefcase"></i>
        </div>
        <h5 className="poppins-font">
          <span className="text-uppercase">{profile?.aboutMe?.firstName} {profile?.aboutMe?.lastName}</span>
          <span className="place open-sans-font">{profile?.summary?.description}</span>
        </h5>
        <p className="open-sans-font text-uppercase py-2">{profile?.summary?.address}</p>
        <p className="open-sans-font text-uppercase py-2">{profile?.summary?.number}</p>
        <p className="open-sans-font text-uppercase py-2">{profile?.summary?.email}</p>
      </li>
    </ul>
  );
};

export default Summary;
