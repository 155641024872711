import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./assets/scss/main.scss";
import { AppStateProvider } from "./contexts/AppStateProvider";
import appReducer, { appInitialState } from "./reducers/app";

ReactDOM.render(
    <AppStateProvider initialState={appInitialState} reducer={appReducer}>
        <App />
    </AppStateProvider>,
    document.getElementById("root")
);

reportWebVitals();
