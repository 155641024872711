import React from "react";

const skillsContent = [
    { skillClass: "p90", skillPercent: "90", skillName: "Flutter" },
    { skillClass: "p90", skillPercent: "90", skillName: "Flutter Bloc" },
    { skillClass: "p90", skillPercent: "90", skillName: "Node JS" },
    { skillClass: "p90", skillPercent: "90", skillName: "Express JS" },
    { skillClass: "p90", skillPercent: "90", skillName: "Nest JS" },
    { skillClass: "p75", skillPercent: "75", skillName: "React JS" },
    { skillClass: "p85", skillPercent: "85", skillName: "Mongo DB" },
    { skillClass: "p85", skillPercent: "85", skillName: "Mongoose" },
    { skillClass: "p70", skillPercent: "70", skillName: "Prisma" },
    { skillClass: "p80", skillPercent: "80", skillName: "socket.io" },
    { skillClass: "p90", skillPercent: "90", skillName: "JavaScript" },
    { skillClass: "p90", skillPercent: "90", skillName: "TypeScript" },
    { skillClass: "p75", skillPercent: "75", skillName: "Handlebars" },
    { skillClass: "p100", skillPercent: "100", skillName: "HTML" },
    { skillClass: "p90", skillPercent: "90", skillName: "Bootstrap" },
    { skillClass: "p70", skillPercent: "70", skillName: "CSS" },
];

const Skills = () => {
    return (
        <>
            {skillsContent.map((val, i) => (
                <div className="col-6 col-md-3 mb-3 mb-sm-5" key={i}>
                    <div className={`c100 ${val.skillClass}`}>
                        <span>{val.skillPercent}%</span>
                        <div className="slice">
                            <div className="bar"></div>
                            <div className="fill"></div>
                        </div>
                    </div>
                    <h6 className="text-uppercase open-sans-font text-center mt-2 mt-sm-4">
                        {val.skillName}
                    </h6>
                </div>
            ))}
        </>
    );
};

export default Skills;
