import React, { useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Link } from "react-router-dom";
import Hero from "../../components/hero/Hero";
import Index from "../../components/about/index";
import Address from "../../components/Address";
import Portfolio from "../../components/portfolio/Portfolio";
import Contact from "../../components/Contact";
import Social from "../../components/Social";
import { useAppStateValue } from "../../contexts/AppStateProvider";
import { appActionTypes } from "../../reducers/app";
import Article from "../../components/article/Article";

const menuItem = [
    { icon: "fa-home", menuName: "Home" },
    { icon: "fa-user", menuName: "About" },
    { icon: "fa fa-briefcase", menuName: "Projects" },
    { icon: "fa fa-file", menuName: "Articles" },
    { icon: "fa-envelope-open", menuName: "Contact" },
];

const HomeDark = () => {
    const [{ selectedTabIndex }, dispatch] = useAppStateValue();

    useEffect(() => {
        document.body.classList.remove("light");
        document
            .querySelector('meta[name="theme-color"]')
            .setAttribute("content", "#111111");
    }, []);

    const handleSelect = (index) => {
        dispatch({
            type: appActionTypes.SWICH_TAB,
            index,
        });
    };

    return (
        <div className="yellow">
            <div className="demo-sticker">
                <Link to="/l">
                    <i className="fa fa-lightbulb-o" aria-hidden="true"></i>
                </Link>
            </div>
            <Tabs selectedIndex={selectedTabIndex} onSelect={handleSelect}>
                <div className="header">
                    <TabList className=" icon-menu  revealator-slideup revealator-once revealator-delay1">
                        {menuItem.map((item, i) => (
                            <Tab className="icon-box" key={i}>
                                <i className={`fa ${item.icon}`}></i>
                                <h2>{item.menuName}</h2>
                            </Tab>
                        ))}
                    </TabList>
                </div>
                {/* End Menu Content */}

                <div className="tab-panel_list">
                    {/* Hero Content Starts */}
                    <TabPanel className="home ">
                        <div
                            className="container-fluid main-container container-home p-0 "
                            data-aos="fade-up"
                            data-aos-duration="1200"
                        >
                            <div className="color-block d-none d-lg-block"></div>
                            <Hero />
                        </div>
                    </TabPanel>
                    {/* Hero Content Ends */}

                    {/* About Content Starts */}
                    <TabPanel className="about">
                        <div data-aos="fade-up" data-aos-duration="1200">
                            <div className="title-section text-left text-sm-center">
                                <h1>
                                    ABOUT <span>ME</span>
                                </h1>
                                <span className="title-bg">Resume</span>
                            </div>
                            {/* End title */}
                            <Index />
                        </div>
                    </TabPanel>
                    {/* About Content Ends */}

                    {/* Portfolio Content Starts */}
                    <TabPanel className="portfolio professional">
                        <div data-aos="fade-up" data-aos-duration="1200">
                            <div className="title-section text-left text-sm-center">
                                <h1>
                                    MY <span>PROJECTS</span>
                                </h1>
                                <span className="title-bg">Works</span>
                            </div>
                            {/* End title */}
                            <div className="container grid-gallery main-content">
                                <Portfolio />
                            </div>
                        </div>
                    </TabPanel>
                    {/* Portfolio Content Ends */}

                    {/* Article Content Starts */}
                    <TabPanel className="portfolio professional">
                        <div data-aos="fade-up" data-aos-duration="1200">
                            <div className="title-section text-left text-sm-center">
                                <h1>
                                    MY <span>ARTICLES</span>
                                </h1>
                                <span className="title-bg">Blogs</span>
                            </div>
                            {/* End title */}
                            <div className="container grid-gallery main-content">
                                <Article />
                            </div>
                        </div>
                    </TabPanel>
                    {/* Article Content Ends */}

                    {/* Contact Content Starts */}
                    <TabPanel className="contact">
                        <div
                            className="title-section text-left text-sm-center"
                            data-aos="fade-up"
                            data-aos-duration="1200"
                        >
                            <h1>
                                get in <span>touch</span>
                            </h1>
                            <span className="title-bg">contact</span>
                        </div>
                        <div
                            className="container"
                            data-aos="fade-up"
                            data-aos-duration="1200"
                        >
                            <div className="row">
                                {/*  Left Side Starts */}
                                <div className="col-12 col-lg-4">
                                    <h3 className="text-uppercase custom-title mb-0 ft-wt-600 pb-3">
                                        Don't be shy !
                                    </h3>
                                    <p className="open-sans-font mb-4">
                                        Feel free to get in touch with me. I am
                                        always open to discussing new projects,
                                        creative ideas or opportunities to be
                                        part of your visions.
                                    </p>
                                    <Address />
                                    {/* End Address */}

                                    <Social />
                                    {/* End Social */}
                                </div>
                                {/* Left Side Ends */}

                                {/*  Contact Form Starts  */}
                                <div className="col-12 col-lg-8">
                                    <Contact />
                                </div>
                                {/*  Contact Form Ends */}
                            </div>
                        </div>
                        {/* End .container */}
                    </TabPanel>
                    {/* Contact Content Ends */}
                </div>
            </Tabs>
        </div>
    );
};

export default HomeDark;
