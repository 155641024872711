import React from "react";
import { useAppStateValue } from "../contexts/AppStateProvider";

const Social = () => {
  const [{ profile }] = useAppStateValue();

  const SocialShare = [
    {
      iconName: "fa fa-whatsapp",
      link: profile?.aboutMe?.whatsapp,
    },
    {
      iconName: "fa fa-instagram",
      link: profile?.aboutMe?.instagram,
    },
    {
      iconName: "fa fa-linkedin",
      link: `https://linkedin.com/in/${profile?.aboutMe?.linkedin}`,
    },
    {
      iconName: "fa fa-github",
      link: `https://github.com/${profile?.aboutMe?.github}`,
    },
  ];

  return (
    <ul className="social list-unstyled pt-1 mb-5">
      {SocialShare.map((val, i) => (
        <li key={i}>
          <a href={val.link} target="_blank" rel="noreferrer">
            <i className={val.iconName}></i>
          </a>
        </li>
      ))}
    </ul>
  );
};

export default Social;
